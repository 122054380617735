.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;

    align-items: flex-start;
}

.logout {
    position: absolute;
    top: 10px;
    right: 10px;
}

.app-left-column {
    width: 20%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}

.topplayercount {
    display: flex;
    flex-direction: column;
    /*align-items:center;*/

    padding-bottom: 20px;
    background-color: darkkhaki;
}
.topplayercount h3 {
    padding-left: 20px;
}
.topplayercount_count {
    text-align: center;
    width: 100%;
    padding-right: 50px;
    font-size: 20px;
    font-weight: 700;
}

.progresslog {
    height: 100%;
    background: lightgreen;
}
.progresslog h3 {
    padding-left: 20px;
}
.progresslog ul {
    list-style-position: outside;
    margin: 0;
    padding-inline-start: 30px;
}

.app-right-column {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #e7ebf0;
}
.app_title {
    padding-left: 20px;
}
.player_selection {
    padding-left: 20px;
    display: flex;
    align-items: center;
    column-gap: 50px;
}

.player_dinolist {
    height: 100%;
    overflow: auto;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
}
.dino-grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto;
    grid-gap: 10px;
}
.dino-grid-inactive {
    padding-top: 20px;
    width: 100%;

    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto;
    grid-gap: 10px;
}

.add-dino {
    min-height: 210px;
    padding-left: 20px;
    background: lightcoral;
}
