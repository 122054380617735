.homepage_wrapper {
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    row-gap: 20px;
}

.homepage_wrapper a:visited {
    color: unset;
    text-decoration: none;
}
.homepage_wrapper a:link {
    color: unset;
    text-decoration: none;
}
.homepage_wrapper a:active {
    color: unset;
    text-decoration: none;
}
.homepage_wrapper a:hover {
    background-color: #3f51b5;
    color: whitesmoke;
}

.homepage_box {
    width: 200px;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
