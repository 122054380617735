.weekly_wrapper {
    margin-top: 20px;
    margin-left: 20px;
}

.segment_wrapper {
    width: auto;
    margin: 20px;
    padding: 10px;
    border: 1px solid lightblue;
}
.segment_topline {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.weekly_add {
    margin-left: 50px;
}

.segment_events {
    padding-top: 10px;
    padding-left: 25px;
    padding-bottom: 5px;
    display: flex;
    column-gap: 20px;
    align-items: center;
}
.segment_attempts {

}
.segment_dino {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 13px;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}



.weekly_summary {
    width: 80%;
    margin-left: 20px;
    margin-top: 40px;
}