/** This adds space in input controls between the value and the increment/decrement arrows.
  * Only works on Chrome I believe, may not be able to fix on Firefox.
  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    margin-left: 10px;
}

.adddino_wrapper {
    width: 100%;
    height: 100%;
}
.adddino {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.adddino_rows {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-top: 10px;
}
.adddino_btn {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    align-items: flex-end;
    justify-content: center;
    padding-right: 50px;
}
.adddino_toprow {
    width: 100%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.adddino_image {
    width: 50px;
}
.adddino_inputsection {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: flex-end;
}
.adddino_secondrow {
    display: flex;
}
.statbuff_option {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adddino_stats_wrapper {
    display: flex;
}
.adddino_stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 10px;
}
.adddino_buffinfo {
    display: flex;
    justify-content: flex-end;
}

.newdinostats {
    display: flex;
    column-gap: 20px;
}
.statselect {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.statselect_details {
    width: 100%;
    padding-left: 20px;
    display: flex;
    column-gap: 20px;
}
